import React, { FC } from "react";
import { ContactUs } from "../components/ContactUs";
import Layout from "../components/Layout/Layout";

const Contact: FC = () => {
  return (
    <Layout
      currentPage="/contact"
      pathname="/contact"
      title="Beartwigs - Contact"
    >
      <ContactUs />
    </Layout>
  );
};

export default Contact;
